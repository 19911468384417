import slide1 from "../../assets/footer/Bottom 1.jpg"
import slide2 from "../../assets/footer/Bottom 6.jpg"
import slide4 from "../../assets/footer/Bottom 4.jpg"
import slide5 from "../../assets/footer/Bottom 5.jpeg"
import slide6 from "../../assets/footer/Bottom 2.jpg"
import slide7 from "../../assets/footer/Bottom 7.jpg"
import slide8 from "../../assets/footer/Bottom 8.jpeg"
import slide9 from "../../assets/footer/Bottom 9.jpg"
import slide10 from "../../assets/footer/Bottom 10.jpeg"
import slide11 from "../../assets/footer/Bottom 11.jpeg"
import slide13 from "../../assets/footer/Bottom 12.jpeg"
import slide12 from "../../assets/footer/Bottom 13.jpeg"

export const FooterData = [
    {
        img:slide9,
    },
    {
        img:slide1,
    },
    {
        img:slide2,
    },
    {
        img:slide4,
    },
    {
        img:slide5,
    },
    {
        img:slide6,
    },
    {
        img:slide7,
    },
    {
        img:slide8,
    },
    {
        img:slide10,
    },
    {
        img:slide11,
    },
    {
        img:slide12,
    },
    {
        img:slide13,
    },
    
]
